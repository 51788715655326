import React from 'react'

// Images
import chevron from 'img/icon/chevron_right.svg'

// Components
import AgendaInnerItem from 'elements/Agenda/AgendaInnerItem'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const StyledAgendaItem = styled.div``

const ItemTitle = styled.h2`
  font-weight: ${(props) => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.secondary};
  font-size: ${(props) => props.theme.font.size.lm};
  margin-bottom: 0;

  &::first-letter {
    text-transform: uppercase;
  }
`

const TitleIcon = styled.img`
  height: 19px;
  width: 10px;
  transition: 0.25s;

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(90deg);
    `}
`

const TitleContainer = styled.div`
  cursor: pointer;
`

const ItemContent = styled(motion.div)`
  overflow-y: hidden;

  h3 {
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    color: ${(props) => props.theme.color.text.secondary};
  }
`

const ContentHeaders = styled.div``

const AgendaItem = ({ isOpen, setOpen, index, content, className }) => {
  return (
    <StyledAgendaItem className={`${className ? `${className}` : ``}`}>
      <TitleContainer
        onClick={() => setOpen(index)}
        className="d-flex pb-4 align-items-center"
      >
        <ItemTitle>{content.month}</ItemTitle>
        <TitleIcon isOpen={isOpen} className="ms-3" src={chevron} alt="" />
      </TitleContainer>

      <ItemContent
        initial={{ height: '0px' }}
        animate={isOpen ? { height: '100%' } : { height: '0px' }}
        className={isOpen && `mb-5`}
      >
        <ContentHeaders className="pb-1 row">
          <div className="col-2">
            <h3>Startdatum</h3>
          </div>
          <div className="col-2">
            <h3>Dagdeel</h3>
          </div>
          <div className="col-3">
            <h3>Opleiding</h3>
          </div>
          <div className="col-3">
            <h3>Locatie</h3>
          </div>
          <div className="col-2">
            <h3>Inschrijven</h3>
          </div>
        </ContentHeaders>

        {content.days
          .sort((a, b) => a.onlyDate - b.onlyDate)
          .map((d, index) => (
            <AgendaInnerItem
              key={index}
              index={index}
              delay={index * 0.2}
              content={d}
            />
          ))}
      </ItemContent>
    </StyledAgendaItem>
  )
}

export default AgendaItem
