import React, { useState, useLayoutEffect } from 'react'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const StyledAgendaInnerItem = styled(motion.div)`
  padding: 10px 0 10px 0;

  p {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  ${(props) =>
    props.index !== 0 &&
    css`
      border-top: 1px solid #bfcbd2;
    `}
`

const Column = styled.div`
  p {
    color: ${(props) => props.theme.color.text.secondary};
  }
`

const AgendaInnerItem = ({ delay, index, content, className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.4,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledAgendaInnerItem
      ref={ref}
      index={index}
      initial={{ x: 20, opacity: 0.3 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: 'spring', damping: 20 }}
      className={`row ${className ? `${className}` : ``}`}
    >
      <Column className="col-2">
        <p className="mb-0">{content.date}</p>
      </Column>
      <Column className="col-2">
        <p className="mb-0">{content.dagdeel}</p>
      </Column>
      <Column className="col-3">
        <p className="mb-0">{parse(content.education)}</p>
      </Column>
      <Column className="col-3">
        <p className="mb-0">{content.location}</p>
      </Column>
      <div className="col-2">
        <CustomLink to={content.path}>
          <p className="color-contrast mb-0">Naar opleiding</p>
        </CustomLink>
      </div>
    </StyledAgendaInnerItem>
  )
}

export default AgendaInnerItem
