import React from 'react'

// Elements
import Customers from 'elements/Customers'
import News from 'elements/News'
import ChooseEducation from 'elements/Education/ChooseEducation'

const ExtraContent = ({ content, className }) => {
  return (
    <div className={`${className ? `${className}` : ``}`}>
      {content.map(
        (c, index) =>
          ({
            'klanten-statisch': (
              <Customers key={index} className="py-lg-5 py-3" />
            ),
            nieuws: <News key={index} className="py-lg-5 py-3" />,
            'kies-een-opleiding': <ChooseEducation className="py-lg-5 py-3" />,
          }[c.slug])
      )}
    </div>
  )
}

export default ExtraContent
