import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import AgendaItem from 'components/elements/Agenda/AgendaItem'

// Third Party
import styled from 'styled-components'
import moment from 'moment'

const StyledAgenda = styled.div``

const Agenda = ({ className }) => {
  const {
    opleidingen: { nodes: opleidingen },
  } = useStaticQuery(graphql`
    {
      opleidingen: allWpOpleiding(sort: { order: ASC, fields: date }) {
        nodes {
          ...OpleidingFilterFrag
        }
      }
    }
  `)

  // Get all events bundled up by their first date

  const filteredOpleidingen = opleidingen.filter(
    (o) => o.postOpleiding.events !== null
  )

  filteredOpleidingen.forEach((opleiding) => {
    const parsedEvents = []

    if (Array.isArray(JSON.parse(opleiding.postOpleiding.events))) {
      JSON.parse(opleiding.postOpleiding.events).forEach((event) => {
        if (!parsedEvents.find((e) => e.Evenement_Id === event.Evenement_Id)) {
          parsedEvents.push(event)
        }
      })
    } else {
    }

    opleiding.parsedEvents = parsedEvents
  })

  // Filter out courses without any events
  // Then order and sort into months
  const events = []
  filteredOpleidingen
    .filter((f) => f.parsedEvents.length !== 0)
    .forEach((f) => {
      f.parsedEvents.forEach((p) => {
        const eventDate = new Date(p.Datum_sessie)

        if (
          events.filter(
            (e) =>
              e.month ===
              eventDate.toLocaleDateString('nl-NL', {
                month: 'long',
                year: 'numeric',
              })
          ).length === 0
        ) {
          events.push({
            month: eventDate.toLocaleDateString('nl-NL', {
              month: 'long',
              year: 'numeric',
            }),
            onlyYear: eventDate.toLocaleDateString('nl-NL', {
              year: 'numeric',
            }),
            onlyMonth: eventDate.toLocaleDateString('nl-NL', { month: 'long' }),
            days: [
              {
                date: eventDate.toLocaleDateString('nl-NL', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                }),
                onlyDate: eventDate.toLocaleDateString('nl-NL', {
                  day: 'numeric',
                }),
                education: f.title,
                location: p.Opmerking || p.Omschrijving || '-',
                path: `/opleidingen/${f.opleidingTypes.nodes[0].slug}/${f.slug}/`,
                dagdeel: `${
                  f.title === 'Bootcamp Koeltechniek'
                    ? '08:00 : 16:30'
                    : `${moment(p.Begintijd, 'HH:mm').format(
                        'HH:mm'
                      )} : ${moment(p.Eindtijd, 'HH:mm').format('HH:mm')}`
                }`,
              },
            ],
          })
        } else {
          events
            .find(
              (ef) =>
                ef.month ===
                eventDate.toLocaleDateString('nl-NL', {
                  month: 'long',
                  year: 'numeric',
                })
            )
            .days.push({
              date: eventDate.toLocaleDateString('nl-NL', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
              }),
              onlyDate: eventDate.toLocaleDateString('nl-NL', {
                day: 'numeric',
              }),
              education: f.title,
              location: p.Opmerking || p.Omschrijving || '-',
              path: `/opleidingen/${f.opleidingTypes.nodes[0].slug}/${f.slug}/`,
              dagdeel: `${
                f.title === 'Bootcamp Koeltechniek'
                  ? '08:00 : 16:30'
                  : `${moment(p.Begintijd, 'HH:mm').format('HH:mm')} : ${moment(
                      p.Eindtijd,
                      'HH:mm'
                    ).format('HH:mm')}`
              }`,
            })
        }
      })
    })

  const months = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ]

  const currentDate = months.indexOf(
    new Date().toLocaleDateString('nl-NL', { month: 'long' })
  )
  const currentYear = new Date().toLocaleDateString('nl-NL', {
    year: 'numeric',
  })

  const [isOpen, setOpen] = useState(0)

  return (
    <StyledAgenda className={`${className ? `${className}` : ``}`}>
      <div className="container px-lg-5">
        {events
          .filter((ev) => ev.onlyYear === currentYear)
          .filter((ev) => months.indexOf(ev.onlyMonth) >= currentDate)
          .sort(
            (a, b) => months.indexOf(a.onlyMonth) - months.indexOf(b.onlyMonth)
          )
          .map((c, index) => (
            <AgendaItem
              index={index}
              setOpen={setOpen}
              isOpen={isOpen === index}
              alreadyOpen={index === 0}
              key={index}
              className=""
              content={c}
            />
          ))}
        {events
          .filter((ev) => ev.onlyYear > currentYear)
          .sort(
            (a, b) => months.indexOf(a.onlyMonth) - months.indexOf(b.onlyMonth)
          )
          .map((c, index) => (
            <AgendaItem
              index={index + 12}
              setOpen={setOpen}
              isOpen={isOpen === index + 12}
              alreadyOpen={false}
              key={index + 12}
              className=""
              content={c}
            />
          ))}
      </div>
    </StyledAgenda>
  )
}

export default Agenda
