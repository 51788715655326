import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ContentLink from 'elements/ContentLink'

// Third Party
import styled from 'styled-components'

const StyledCustomers = styled.div``

const StyledImg = styled(Plaatjie)`
  height: auto;
  width: 100%;

  img {
    background-size: contain !important;
  }
`

const ImgContainer = styled.div`
  width: 156px;
  height: 126px;
`

const Customers = ({ className }) => {
  const {
    components,
    klanten: { nodes: klanten },
  } = useStaticQuery(graphql`
    {
      components: wpComponent(databaseId: { eq: 352 }) {
        componentKlantenStatisch {
          description
        }
      }

      klanten: allWpKlant(sort: { fields: date }) {
        nodes {
          postKlanten {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 400)
                }
              }
              slug
              title
            }
          }
        }
      }
    }
  `)

  return (
    <StyledCustomers className={`container ${className ? `${className}` : ``}`}>
      <ContentLink content={components.componentKlantenStatisch} />
      <div className="row">
        {klanten.map((k, index) => (
          <div
            key={index}
            className="col-md-5ths col-6 d-flex justify-content-center align-items-center"
          >
            <ImgContainer className="d-flex justify-content-center align-items-center">
              {/* <StyledImg fluid={k.acf.image.localFile.childImageSharp.fluid} alt="" /> */}
              <StyledImg image={k.postKlanten.image} alt="" />
            </ImgContainer>
          </div>
        ))}
      </div>
    </StyledCustomers>
  )
}

export default Customers
