import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import BackgroundHexagon from 'elements/BackgroundHexagon'
import ContentLink from 'elements/ContentLink'

// Components
import CustomLink from 'components/shared/CustomLink'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const Education = styled(motion.div)`
  width: 100%;
  height: 75px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  background-color: ${(props) => props.theme.color.face.light};
  padding: 0 10px 0 10px;

  p {
    font-size: ${(props) => props.theme.font.size.ms};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.color.text.secondary};

    &:hover {
      text-decoration: none;
    }
  }
`

const StyledChooseEducation = styled.div``

const StyledCustomLink = styled(CustomLink)`
  padding: 12px 12px 12px 12px;

  @media screen and (max-width: 575px) {
    padding: 5px 5px 5px 5px;
  }
`

const ImageContainer = styled.div`
  max-height: 24px;
  max-width: 28px;
`

const StyledImage = styled(Plaatjie)`
  object-fit: contain;

  img {
    height: 24px !important;
    width: unset !important;
    object-fit: contain !important;
  }
`

const ChooseEducation = ({ className }) => {
  const {
    components,
    types: { nodes: types },
  } = useStaticQuery(graphql`
    {
      components: wpComponent(databaseId: { eq: 364 }) {
        slug
        componentKiesEenOpleiding {
          description
        }
      }

      types: allWpOpleidingType {
        nodes {
          count
          databaseId
          name
          slug
          id
          uri

          categorieOpleidingType {
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 300)
                }
              }
            }
          }
        }
      }
    }
  `)

  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledChooseEducation
      className={`position-relative ${className ? `${className}` : ``}`}
    >
      <BackgroundHexagon noMobile position="bottom-right" />
      <div className="container">
        <ContentLink content={components.componentKiesEenOpleiding} />
        <div className="row pt-4 justify-content-center justify-content-lg-end">
          {types.map((e, index) => (
            <StyledCustomLink
              to={e.uri}
              key={e.databaseId}
              className="position-relative col-6 col-md-3 d-flex"
            >
              <Education
                ref={ref}
                // initial={{ opacity: 0.3, y: 50 }}
                // animate={isVisible || view ? { opacity: 1, y: 0, transition: {delay: index * 0.1, type: 'spring'} } : { opacity: 0.3, y: 50 }}
                whileHover={{
                  y: -5,
                  transition: { type: 'spring', duration: 0.3, delay: 0 },
                }}
                className="d-flex align-items-center"
              >
                <div>
                  <ImageContainer>
                    <StyledImage image={e.categorieOpleidingType.icon} alt="" />
                  </ImageContainer>
                  <p className="mb-0">{`${parse(e.name)} (${e.count})`}</p>
                </div>
              </Education>
            </StyledCustomLink>
          ))}
        </div>
      </div>
    </StyledChooseEducation>
  )
}

export default ChooseEducation
